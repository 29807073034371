import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Intro from "../components/page-intro"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { motion } from 'framer-motion'


const duration = 0.25

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.08,
      delayChildren: duration,
    },
  },
}
// const item = {
//   hidden: { x: 0, y: 20, opacity: 0 },
//   visible: {
//     y: 0,
//     x: 0,
//     opacity: 1,
//   },
// }

const About = () => {
  const data = useStaticQuery(graphql`
  query {
  allDirectory {
    edges {
      node {
        id
      }
    }
  }
  ben: file(relativePath: {eq: "ben-profile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  aboutSocial: file(relativePath: {eq: "social-about.jpg"}) {
    childImageSharp {
      fixed(width: 1200, height: 630, quality: 100) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
}
`)
  return (
    <>
      <SEO 
        title="About Benjamin Cross" 
        description="Nottingham based freelance creative designer. Working in brand, UX/UI, digital design and print." 
        image={data.aboutSocial.childImageSharp.fixed}
      />
      <Helmet
        htmlAttributes={{
          class: 'about text-white bg-black',
        }}
      />
      
      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
      >

        <Intro 
          bgColor=""
          borderColor="border-black border-opacity-10"
          introHeader={["About —"]}
          introSubHeader={["Hi, there! 👋"]}
          introSummary="">
        </Intro>  

      </motion.section>


      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">

        <div className="border-b"></div>

        <div className="border-b border-l">

          <div className="md:grid grid-cols-2">
            <div class="mob:border-b relative">
              <Img backgroundColor="#F1EBE8"  className=""  fluid={data.ben.childImageSharp.fluid} />
            </div>
            <div className="p-10 md:p-12 md:border-l relative lg:text-2xl xl:pr-48">
              <h2>Biography</h2>
              <p className="mb-6">Originally from North Wales, I now reside in Nottingham with my wife and two boys. I've gained a decade of experience as a web designer — with a background in graphic design.</p>
              <p className="mb-10">I've worked on a variety of projects, gaining experience designing websites, user experience, user interfaces, brands and design for print. I have a passion for strong typography, simple interfaces and a user-centric design approach.</p>      
            </div>
          </div>

        </div>

        <div className="border-b border-l"></div>
      </div>

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">

        <div className="border-b"></div>

        <div className="border-b border-l">

          <div className="md:grid grid-cols-2">
            <div class="">
              
            </div>
            <div className="p-10 md:p-12 md:border-l relative md:text-2xl">
              <h2>Services</h2>
              <ul className="list-disc">
                <li>web design</li>
                <li>branding and identity design</li>
                <li>brand guidelines</li>
                <li>front-end development – HTML/CSS/JS</li>
                <li>design for print and publications</li>
                <li>user experience</li>
                <li>user interface design</li>
                <li>responsive design</li>  
              </ul> 
            </div>
          </div>

        </div>

        <div className="border-b border-l"></div>
      </div>
      
    </>
  )
}



export default About

